// React Block UI

.loading-indicator {
  text-align: center;
}

.loading-bullet {
  display: inline-block;
  opacity: 0;
  font-size: 2em;
  color: $primary;
}

.block-ui {
  position: relative;
  min-height: 3em;
}
.small-select {
  padding: 0 !important;
  .MuiPaper-root.MuiCard-root {
    box-shadow: none !important;
    padding: 10px !important;
  }
}
.block-ui-container {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;
}

.block-ui-container:focus {
  outline: none;
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  background: transparentize($white, .2);
}

.block-ui-message-container {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-ui-message {
  color: $body-color;
  background: none;
  z-index: 1011;
}

// Dark overlay

.block-loading-overlay-dark {
  .block-ui-overlay {
    background: transparentize($black, .2);
  }
}

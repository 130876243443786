// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.custom-number-input {
  width: 100%;
  border: 1px solid $gray-700;
  transition: 0.2s;
  &:hover,
  &:focus {
    box-shadow: $input-focus-box-shadow;
    border-color: $primary;
  }
}
.pdd-10 {
  @include media-breakpoint-down(md) {
    padding: 1.5rem;
  }
}
.back-tree {
  position: absolute;
  top: 0;
  right: -50px;
  height: 70%;
  width: 100%;
  background-position: right top;
  background-size: contain;
  background-repeat: no-repeat;
  @include media-breakpoint-down(md) {
    display: none;
  }
  
}